import { useAppState } from 'hooks';
import { Actions } from './components';

import './Header.scss';
import { VIEWS } from 'config/global';

const Header = () => {
    const { setView } = useAppState();
    
    const setWelcomeView = () => {
        setView(VIEWS.WELCOME);
    };

    return (
        <header className="header">
            <div className="header__left">
                <img
                    className="header__logo"
                    src="/icons/icon-128x128.png"
                    alt="Logo"
                    onClick={setWelcomeView}
                />
            </div>
            <span
                className="header__name"
                onClick={setWelcomeView}
            >Allchats Extension</span>
            <Actions />
        </header>
    );
};

export default Header;
