import cn from 'classnames';
import { useAppState } from 'hooks';
import { VIEWS } from 'config/global';

import './Actions.scss';

const Actions = () => {
    const { view, setView } = useAppState();

    const selectMessengersHandler = () => {
        setView(prevState => {
            return prevState === VIEWS.PIN_MESSENGERS
                ? VIEWS.WELCOME
                : VIEWS.PIN_MESSENGERS;
        });
    };

    return (
        <ul className="actions">
            <li
                className={cn('actions__item', { active: view === VIEWS.PIN_MESSENGERS })}
                onClick={selectMessengersHandler}
            >
                <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg"> <line y1="1" x2="19" y2="1" stroke="#535365" strokeWidth="2"/> <line y1="7" x2="19" y2="7" stroke="#535365" strokeWidth="2"/> <line y1="13" x2="19" y2="13" stroke="#535365" strokeWidth="2"/> </svg>
            </li>
        </ul>
    );
};

export default Actions;
