import React from 'react';
import { Header, MessengersSelector, Sidebar, Welcome } from 'components';
import { useAppState } from 'hooks';
import { VIEWS } from 'config/global';

function App() {
  const { view } = useAppState();

  const renderContent = () => {
    switch (view) {
      case VIEWS.WELCOME:
        return <Welcome />;
      case VIEWS.PIN_MESSENGERS:
        return <MessengersSelector />;
      default:
        return null;
    }
  };

  return (
    <div className="app">
      <Header />
      <Sidebar />
      {renderContent()}
    </div>
  );
}

export default App;
