export const defaultMessengers = [
    {
        key: 'chat-gpt',
        name: 'Chat GPT',
        url: 'https://chat.openai.com',
        pinned: true,
    },
    {
        key: 'whatsapp',
        name: 'Whatsapp',
        url: 'https://web.whatsapp.com',
        pinned: true,
    },
    {
        key: 'messenger',
        name: 'Messenger',
        url: 'https://www.facebook.com/messages',
        pinned: true,
    },
    {
        key: 'twitter',
        name: 'Twitter',
        url: 'https://twitter.com',
        pinned: true,
    },
    {
        key: 'telegram',
        name: 'Telegram',
        url: 'https://web.telegram.org',
        pinned: true,
    },
    {
        key: 'slack',
        name: 'Slack',
        url: 'https://app.slack.com',
        pinned: true,
    },
    {
        key: 'skype',
        name: 'Skype',
        url: 'https://web.skype.com',
        pinned: true,
    },
    {
        key: 'discord',
        name: 'Discord',
        url: 'https://discord.com/app',
        pinned: true,
    },
    {
        key: 'crew-msn',
        name: 'Crew msn',
        url: 'https://crewapp.com/app/login',
        pinned: true,
    },
    {
        key: 'instagram',
        name: 'Instagram',
        url: 'https://www.instagram.com',
        pinned: true,
    },
    {
        key: 'wechat',
        name: 'WeChat',
        url: 'https://web.wechat.com',
        pinned: true,
    },
    {
        key: 'hangouts',
        name: 'Hangouts',
        url: 'https://hangouts.google.com',
        pinned: true,
    },
];
