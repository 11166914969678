
import { useAppState } from 'hooks';

import './Sidebar.scss';

const Sidebar = () => {
    const { messengers, renderMessengerIcon } = useAppState();

    const renderMessenger = (messenger) => (
        <a
            key={messenger.key}
            href={messenger.url}
            className="sidebar__item"
            target="_blank"
            rel="noreferrer"
        >
            {renderMessengerIcon(messenger.key)}
        </a>
    );

    const renderMessengers = () => messengers.reduce((prev, curr) => {
        return curr.pinned
            ? [...prev, renderMessenger(curr)]
            : prev;
    }, []);

    return (
        <div className="sidebar">
            {renderMessengers()}
        </div>
    );
};

export default Sidebar;
