export const LOCAL_STORAGE_KEYS = {
    MESSENGERS_KEY: 'messengers',
};

export const VIEWS = {
    WELCOME: 'welcome',
    PIN_MESSENGERS: 'PIN_MESSENGERS',
};

export const MESSENGER_KEYS = {
    CHAT_GPT: 'chat-gpt',
    WHATSAPP: 'whatsapp',
    MESSENGER: 'messenger',
    TWITTER: 'twitter',
    TELEGRAM: 'telegram',
    SLACK: 'slack',
    SKYPE: 'skype',
    DISCORD: 'discord',
    CREW_MSN: 'crew-msn',
    INSTAGRAM: 'instagram',
    WECHAT: 'wechat',
    HANGOUTS: 'hangouts',
};
