const Slack = () => (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.66117 17.0571C5.66117 18.5888 4.42324 19.8268 2.89155 19.8268C1.35987 19.8268 0.121931 18.5888 0.121931 17.0571C0.121931 15.5255 1.35987 14.2875 2.89155 14.2875H5.66117V17.0571ZM7.04598 17.0571C7.04598 15.5255 8.28392 14.2875 9.8156 14.2875C11.3473 14.2875 12.5852 15.5255 12.5852 17.0571V23.9812C12.5852 25.5129 11.3473 26.7508 9.8156 26.7508C8.28392 26.7508 7.04598 25.5129 7.04598 23.9812V17.0571Z" fill="#E01E5A"/>
        <path d="M9.81426 5.93653C8.28258 5.93653 7.04464 4.6986 7.04464 3.16691C7.04464 1.63523 8.28258 0.397293 9.81426 0.397293C11.3459 0.397293 12.5839 1.63523 12.5839 3.16691V5.93653H9.81426ZM9.81426 7.34233C11.3459 7.34233 12.5839 8.58026 12.5839 10.1119C12.5839 11.6436 11.3459 12.8816 9.81426 12.8816H2.86923C1.33755 12.8816 0.0996094 11.6436 0.0996094 10.1119C0.0996094 8.58026 1.33755 7.34233 2.86923 7.34233H9.81426Z" fill="#36C5F0"/>
        <path d="M20.9147 10.1119C20.9147 8.58026 22.1527 7.34233 23.6844 7.34233C25.216 7.34233 26.454 8.58026 26.454 10.1119C26.454 11.6436 25.216 12.8816 23.6844 12.8816H20.9147V10.1119ZM19.5299 10.1119C19.5299 11.6436 18.292 12.8816 16.7603 12.8816C15.2286 12.8816 13.9907 11.6436 13.9907 10.1119V3.16691C13.9907 1.63523 15.2286 0.397293 16.7603 0.397293C18.292 0.397293 19.5299 1.63523 19.5299 3.16691V10.1119Z" fill="#2EB67D"/>
        <path d="M16.7603 21.2116C18.292 21.2116 19.5299 22.4495 19.5299 23.9812C19.5299 25.5129 18.292 26.7508 16.7603 26.7508C15.2286 26.7508 13.9907 25.5129 13.9907 23.9812V21.2116H16.7603ZM16.7603 19.8268C15.2286 19.8268 13.9907 18.5888 13.9907 17.0571C13.9907 15.5255 15.2286 14.2875 16.7603 14.2875H23.7053C25.237 14.2875 26.475 15.5255 26.475 17.0571C26.475 18.5888 25.237 19.8268 23.7053 19.8268H16.7603Z" fill="#ECB22E"/>
    </svg>
);

export default Slack;
