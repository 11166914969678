import { useCallback, useEffect, useState } from 'react';
import { defaultMessengers } from './config';
import { LOCAL_STORAGE_KEYS, MESSENGER_KEYS } from 'config/global';
import {
    ChatGPTIcon,
    CrewMsnIcon,
    DiscordIcon,
    HangoutsIcon,
    InstagramIcon,
    MessengerIcon,
    SkypeIcon,
    SlackIcon,
    TelegramIcon,
    TwitterIcon,
    WeChatIcon,
    WhatsappIcon
} from 'components';

const useMessengers = () => {
    const [messengers, setMessengers] = useState(
        localStorage.getItem(LOCAL_STORAGE_KEYS.MESSENGERS)
            ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.MESSENGERS))
            : defaultMessengers
    );

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.MESSENGERS, JSON.stringify(messengers));
    }, [messengers]);

    const renderMessengerIcon = useCallback((key) => {
        switch (key) {
            case MESSENGER_KEYS.CHAT_GPT:
                return <ChatGPTIcon />;
            case MESSENGER_KEYS.WHATSAPP:
                return <WhatsappIcon />
            case MESSENGER_KEYS.MESSENGER:
                return <MessengerIcon />;
            case MESSENGER_KEYS.TWITTER:
                return <TwitterIcon />;
            case MESSENGER_KEYS.TELEGRAM:
                return <TelegramIcon />;
            case MESSENGER_KEYS.SLACK:
                return <SlackIcon />;
            case MESSENGER_KEYS.SKYPE:
                return <SkypeIcon />;
            case MESSENGER_KEYS.DISCORD:
                return <DiscordIcon />
            case MESSENGER_KEYS.CREW_MSN:
                return <CrewMsnIcon />;
            case MESSENGER_KEYS.INSTAGRAM:
                return <InstagramIcon />;
            case MESSENGER_KEYS.WECHAT:
                return <WeChatIcon />;
            case MESSENGER_KEYS.HANGOUTS:
                return <HangoutsIcon />;
            default:
                return null;
        }
    }, []);

    const togglePinState = useCallback((key) => {
        setMessengers(prevState => {
            const index = prevState.findIndex(item => item.key === key);
            
            return [
                ...prevState.slice(0, index),
                {
                    ...prevState[index],
                    pinned: !prevState[index].pinned,
                },
                ...prevState.slice(index + 1, prevState.length),
            ];
        });
    }, [setMessengers]);

    return {
        messengers,
        renderMessengerIcon,
        togglePinState,
    };
};

export default useMessengers;
