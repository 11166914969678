import cn from 'classnames';
import { useAppState } from 'hooks';

import './MessengersSelector.scss';

const MessengersSelector = () => {
    const { messengers, renderMessengerIcon, togglePinState } = useAppState();

    const renderMessenger = (messenger) => (
        <div
            key={messenger.key}
            className={cn('messengersSelector__item', { active: messenger.pinned })}
            onClick={() => togglePinState(messenger.key)}
        >
            <div className="messengersSelector__checkMark">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <circle cx="12.5" cy="12.5" r="11.5" fill="#E1D8F1" stroke="white" strokeWidth="2"/>
                    <path d="M7.83325 12.5L11.9166 16L17.7499 9" stroke="white" strokeWidth="2.33333"/>
                </svg>
            </div>
            <div className="messengersSelector__icon">
                {renderMessengerIcon(messenger.key)}
            </div>
            <p className="messengersSelector__name">{messenger.name}</p>
        </div>
    );

    const renderEmptyCell = (key) => {
        return <div key={key} className="messengersSelector__item empty" />
    };

    const renderMessengers = () => {
        const remainder = 5 - (messengers.length % 5);
        const numberOfIterations = messengers.length > 15
            ? messengers.length + remainder
            : 15;

        const result = [];

        for (let i = 0; i < numberOfIterations; i++) {
            if (messengers[i]) {
                result.push(renderMessenger(messengers[i]));
            }
            else {
                result.push(renderEmptyCell(i));
            }
        }

        return result;
    }

    return (
        <div className="messengersSelector">
            {renderMessengers()}
        </div>
    );
};

export default MessengersSelector;
