const CrewMsn = () => (
    <svg width="36" height="16" viewBox="0 0 36 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.94225 15.9062C1.04559 15.9062 0.273438 15.3406 0.273438 14.0589C0.273438 12.0793 1.977 10.5765 2.74915 10.1518C3.45566 10.5495 4.19208 10.8915 4.95171 11.1749C2.72309 9.76571 1.29943 7.19637 1.29943 4.3452C1.29943 1.68031 2.70572 0.484436 3.97591 0.484436C4.99708 0.484436 7.41971 1.60792 7.41971 5.92039C7.41971 10.5601 4.99804 15.9101 1.94225 15.9101V15.9062Z" fill="black"/>
        <path d="M24.1261 12.8267H23.0702V9.04312C23.0702 8.31408 22.9576 7.78645 22.7324 7.46021C22.5072 7.13398 22.1288 6.97118 21.5973 6.97183C21.1469 6.97183 20.764 7.17773 20.4487 7.58955C20.1286 8.01526 19.9619 8.53673 19.9758 9.06919V12.8267H18.9208V8.90993C18.9208 7.61464 18.4209 6.967 17.4209 6.967C17.1976 6.96431 16.9769 7.01555 16.7776 7.11637C16.5784 7.21719 16.4063 7.36461 16.2762 7.54611C15.9757 7.93219 15.8255 8.43731 15.8255 9.06146V12.8267H14.7705V6.23346H15.8255V7.27586H15.8515C16.3187 6.47733 17.0007 6.07838 17.8977 6.07903C18.3153 6.07002 18.7242 6.19868 19.0614 6.44516C19.3986 6.69164 19.6453 7.04223 19.7634 7.44284C20.2525 6.53363 20.9815 6.07903 21.9506 6.07903C23.4016 6.07903 24.1267 6.97344 24.1261 8.76225V12.8267Z" fill="black"/>
        <path d="M35.9858 12.8264H34.9309V9.06212C34.9309 7.66388 34.4203 6.96476 33.3991 6.96476C33.1502 6.95937 32.9033 7.01 32.6766 7.11291C32.4499 7.21583 32.2492 7.36839 32.0894 7.55931C31.7445 7.95697 31.572 8.45791 31.572 9.06212V12.8264H30.5161V6.23314H31.572V7.32477H31.5971C32.0952 6.49278 32.8158 6.07678 33.7592 6.07678C34.4798 6.07678 35.0313 6.30939 35.4135 6.77461C35.7957 7.23984 35.9865 7.91225 35.9858 8.79186V12.8264Z" fill="black"/>
        <path d="M25.2773 11.4423V12.5725C25.2773 12.5725 25.8381 12.9769 26.9307 12.9769C28.5898 12.9769 29.3195 12.0513 29.3195 11.0407C29.3195 8.85169 26.3709 9.37482 26.3709 7.92221C26.3709 7.37591 26.8062 6.98115 27.5359 6.98115C28.0624 6.98831 28.5758 7.14606 29.0155 7.43575V6.38949C28.5708 6.17406 28.0821 6.0647 27.588 6.07001C26.1691 6.07001 25.285 6.95605 25.285 8.02066C25.285 10.1856 28.224 9.758 28.224 11.172C28.224 11.8206 27.6913 12.0552 27.0021 12.0552C26.0147 12.0552 25.2744 11.4461 25.2744 11.4461L25.2773 11.4423Z" fill="black"/>
        <path d="M5.83691 14.4363C5.83691 15.1119 6.07146 15.3715 6.43147 15.3715C7.33006 15.3715 8.76144 13.2742 8.70739 11.0716C10.0702 9.63347 11.7043 7.38168 11.7043 5.57773C11.7043 5.1405 11.5798 4.77277 11.0625 4.77277C10.271 4.77277 9.14076 6.13658 8.42362 7.49074C7.73158 8.89413 7.0849 10.4944 6.22589 12.6902C6.02513 13.2413 5.83981 13.9083 5.83981 14.4363H5.83691Z" fill="black"/>
    </svg>
);

export default CrewMsn;
