const WeChat = () => (
    <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6767 0.827637C5.41941 0.827637 0.299805 5.09398 0.299805 10.4269C0.299805 13.4844 1.93524 16.1154 4.56615 17.893L3.14403 22.1593L7.62369 19.3862C8.83249 19.8129 10.2546 20.0262 11.6767 20.0262C17.934 20.0262 23.0536 15.7598 23.0536 10.4269C23.0536 5.09398 17.934 0.827637 11.6767 0.827637Z" fill="url(#paint0_linear_11_95)"/>
        <path opacity="0.05" d="M12.3872 17.1819C12.3872 18.1567 12.5884 19.0903 12.9411 19.9635C18.6082 19.4338 23.0531 15.395 23.0531 10.4268C23.0531 9.82597 22.9791 9.24077 22.8547 8.67121C22.684 8.6641 22.5148 8.64917 22.342 8.64917C16.8526 8.64917 12.3872 12.4768 12.3872 17.1819Z" fill="black"/>
        <path opacity="0.07" d="M12.7432 17.1819C12.7432 18.1418 12.9487 19.0598 13.307 19.9166C18.7971 19.2496 23.0535 15.2869 23.0535 10.4268C23.0535 9.95041 22.9987 9.4868 22.9198 9.02959C22.7278 9.01963 22.5373 9.0047 22.3424 9.0047C17.0493 9.0047 12.7432 12.673 12.7432 17.1819Z" fill="black"/>
        <path d="M31.5861 17.1819C31.5861 12.8444 27.462 9.36023 22.3424 9.36023C17.2228 9.36023 13.0986 12.8444 13.0986 17.1819C13.0986 21.5193 17.2228 25.0035 22.3424 25.0035C23.6223 25.0035 24.8311 24.7902 25.8977 24.4346L29.4529 26.4256L28.4575 23.0125C30.3773 21.5904 31.5861 19.5283 31.5861 17.1819Z" fill="url(#paint1_linear_11_95)"/>
        <path d="M8.83202 7.22723C8.83202 8.00939 8.19207 8.64934 7.40991 8.64934C6.62774 8.64934 5.98779 8.00939 5.98779 7.22723C5.98779 6.44507 6.62774 5.80511 7.40991 5.80511C8.19207 5.80511 8.83202 6.44507 8.83202 7.22723ZM15.9426 5.80511C15.1604 5.80511 14.5205 6.44507 14.5205 7.22723C14.5205 8.00939 15.1604 8.64934 15.9426 8.64934C16.7248 8.64934 17.3647 8.00939 17.3647 7.22723C17.3647 6.44507 16.7248 5.80511 15.9426 5.80511Z" fill="#135D36"/>
        <path d="M20.2089 15.4043C20.2089 15.9731 19.7111 16.4709 19.1423 16.4709C18.5734 16.4709 18.0757 15.9731 18.0757 15.4043C18.0757 14.8354 18.5734 14.3377 19.1423 14.3377C19.7111 14.3377 20.2089 14.8354 20.2089 15.4043ZM25.5418 14.3377C24.9729 14.3377 24.4752 14.8354 24.4752 15.4043C24.4752 15.9731 24.9729 16.4709 25.5418 16.4709C26.1106 16.4709 26.6084 15.9731 26.6084 15.4043C26.6084 14.8354 26.1106 14.3377 25.5418 14.3377Z" fill="#474747"/>
        <defs>
            <linearGradient id="paint0_linear_11_95" x1="0.921269" y1="0.664805" x2="16.5944" y2="18.7584" gradientUnits="userSpaceOnUse">
                <stop stopColor="#33C481"/>
                <stop offset="1" stopColor="#21A366"/>
                </linearGradient>
                <linearGradient id="paint1_linear_11_95" x1="13.4385" y1="9.03385" x2="29.8383" y2="24.0414" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F0F0F0"/>
                <stop offset="1" stopColor="#BBC1C4"/>
            </linearGradient>
        </defs>
    </svg>
);

export default WeChat;
