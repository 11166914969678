import { WelcomeIcon } from './components';

import './Welcome.scss';

const Welcome = () => (
    <div className="welcome">
        <div className="welcome__content">
            <WelcomeIcon />
            <p className="welcome__text welcome__text-top">The Allchat Extension</p>
            <p className="welcome__text welcome__text-middle">Conveniently access and engage with all the popular messengers in a single platform.</p>
            <p className="welcome__text welcome__text-bottom">Step into the world of seamless communication</p>
        </div>
    </div>
);

export default Welcome;
