import { createContext, useState } from 'react';
import { useMessengers } from 'hooks';
import { VIEWS } from 'config/global';

export const AppStateContext = createContext({});

const AppStateWrapper = ({ children }) => {
    const [view, setView] = useState(VIEWS.WELCOME);

    const { messengers, renderMessengerIcon, togglePinState } = useMessengers();

    const contextValue = {
        view,
        messengers,
        setView,
        renderMessengerIcon,
        togglePinState,
    };

    return (
        <AppStateContext.Provider value={contextValue}>
            {children}
        </AppStateContext.Provider>
    );
};

export default AppStateWrapper;
